import { Injectable } from '@angular/core';

import { UserAccountRoutes, UserAuthRoutes } from '@ripeurs/common/types/gateway';
import { DatalakeUser } from '@ripeurs/common/types/service';

import { catchError, Observable, of, Subject, switchMap } from 'rxjs';

import { LocalStorage } from '~app/common/decorator/local-storage.decorator';
import { ApiV2Service } from '~app/services/api-v2.service';
import { UtService } from '~app/services/ut.service';
import { UserPermissions } from '~app/types/user';
import { RegionType } from '~type/service/geo/region';


type UserMe = UserAccountRoutes.UserMe['user'] & { email: string; firstName: string; id: string; lastName: string; phone: string; };

@Injectable({ providedIn: 'root' })
export class UserService {
	public onUpdate = new Subject<{
		logged: boolean,
		user: UserMe | null,
		region: RegionType.Region | null,
	}>();
	@LocalStorage('user_token') private token: string | null | undefined;
	private region: RegionType.Region | null = null;
	private permissionAccepted = [UserPermissions.OPERATOR_UTILITY];

	constructor(
		private readonly _api: ApiV2Service,
		private readonly _ut: UtService,
	) {}

	private _data: any | null = null;

	private get data() { return this._data; }

	private set data(user) {
		if (user !== null) {
			// @TODO Temporaire à importer du serveur
			if (user.meta === undefined) { user.meta = { regions: [] }; }
			if (!user.meta?.regions?.length) { user.meta.regions = this._ut.getMetaRegions(); }
			this.region = user.meta.regions.find(({ id }: RegionType.Region) => id === user.region) || user.meta.regions[0];
		}
		this._data = user;
		this.onUpdate.next({
			logged: this.isLogged(),
			user: this._data,
			region: this.region,
		});
	}

	public getToken(): string | null | undefined { return this.token; }

	public getData(): UserMe & { 'meta.regions'?: RegionType.Region } | null { return this.data; }

	public getRegion(): RegionType.Region | null { return this.region; }

	public loggedGetRegion(): RegionType.Region {
		if (this.region === null) { throw new Error('User not logged'); }
		return this.region;
	}

	public isLogged(): boolean { return this.data !== null; }

	public login(
		email: string,
		password: string,
	): Observable<boolean> {
		return this._api.request<UserAuthRoutes.PostSignin.Def>(
			UserAuthRoutes.PostSignin.config,
			{ body: { email, password } },
		).pipe(
			switchMap(this.handleLoginResponse.bind(this)),
			catchError(() => of(false)),
		);
	}

	public loginToken(): any {
		return this._api.request<UserAccountRoutes.GetMe.Def>(
			UserAccountRoutes.GetMe.config,
			{},
		).pipe(
			switchMap(this.handleLoginResponse.bind(this)),
			catchError(() => of(false)),
		);
	}

	public logout(): void {
		this.data = null;
		this.token = null;
	}

	private handleLoginResponse(
		response: UserAuthRoutes.PostSignin.Def['response'] | UserAccountRoutes.GetMe.Def['response'],
	): Observable<boolean> {
		if (response.code === 'OK') {
			this.data = this.normalizeUserData(response.data.user);
			if ('token' in response.data) { this.token = response.data.token; }
		}
		return of(response.code === 'OK' && this.checkPermissions(response.data.user.permissions));
	}

	private checkPermissions(permissions: string[]): boolean {
		return permissions.some((permission) => this.permissionAccepted.includes(<UserPermissions>permission));
	}

	private normalizeUserData(user: Partial<DatalakeUser.User> | UserMe): Partial<UserMe> {
		return {
			email: (<UserMe>user).email || (<DatalakeUser.User>user).data.email!,
			firstName: (<UserMe>user).firstName || (<DatalakeUser.User>user).data.firstName!,
			lastName: (<UserMe>user).lastName || (<DatalakeUser.User>user).data.lastName!,
			permissions : user.permissions!,
			phone: (<UserMe>user).phone || (<DatalakeUser.User>user).data.phoneNumber!,
			region : user.region,
			userId : (<UserMe>user).id || (<DatalakeUser.User>user).userId,
		};
	}
}
