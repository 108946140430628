import { Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { UserService } from '~app/services/user.service';
import { UtService } from '~app/services/ut.service';
import { dialogData, getSimulatorLink, isPartSimulation, RegionData, RegionsSimulator } from '~app/services/constants/ut.constants';

import { CommonDialogMessage } from '../common/dialog/message/message.component';


@Injectable({ providedIn: 'root' })
export class DialogService {
	private readonly simulatorByZone: RegionsSimulator;

	private get userRegion(): 'FR_IDF' | 'FR_LYON'| 'FR_AIX-MARSEILLE-PROVENCE' {
		const user = this._injector.get(UserService);
		return (<'FR_IDF' | 'FR_LYON' | 'FR_AIX-MARSEILLE-PROVENCE'>user.getRegion()?.id) || 'FR_IDF';
	}

	constructor(
		private readonly _matDialog: MatDialog,
		private readonly _injector: Injector,
		private readonly _ut: UtService,
	) { this.simulatorByZone = this._ut.getSimulatorByZone(); }

	public open(
		component: any,
		options: MatDialogConfig = {},
	) {
		return this._matDialog.open(component, {
			disableClose: true,
			...options,
		});
	}

	public openMessage(
		data: dialogData = {},
		options: MatDialogConfig = {}
	) {
		return this.open(CommonDialogMessage, { data, ...options });
	}

	public openSimulator(isPart: isPartSimulation = false) {
		const simulatorV4 = (siteId: string) => {
			this.openMessage({
				contentIframeUrl: getSimulatorLink(siteId, isPart),
				close: true,
			}, {
				width: '96%',
				maxWidth: '96%',
				height: '98%',
				maxHeight: '98%',
			});
		};
		const popup = this.openMessage({
			title: 'Simulateur',
			content: 'Quel localisation ?',
			close: true,
			buttons: this.simulatorByZone[this.userRegion]
				.map((site: RegionData) => {
					const ids = isPart ? { PART: site.ids.PART } : { PRO: site.ids.PRO, REP: site.ids.REP };

					return Object.entries(ids)
					.filter(entry => {
						const id = entry[1] as string;
						return id;
					  })
					.map(([type, id]) => ({
							title: site.title(type === 'PRO' ? undefined : (type as 'REP' | 'PART')),
							iconRight: site.iconRight,
							click: () => {
								popup.close();
								simulatorV4(id as string);
							}
						}
					));
				})
				.reduce((acc, val) => acc.concat(val), [])
				.sort((a, b) => b.title.localeCompare(a.title)),
		});
	}
}
