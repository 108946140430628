import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SwUpdate, VersionEvent } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';
import { stringify } from 'qs';
import { debounceTime, delay, distinctUntilChanged, filter } from 'rxjs';

import { environment } from 'src/environments/environment';

import * as APP_VERSION from '../../package.json';
import { DebugService } from './services/debug.service';
import { DialogService } from './services/dialog.service';
import { UiService } from './services/ui.service';
import { UserService } from './services/user.service';


@Component({
	selector: 'ripeurs-operator-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
	@ViewChild('router', { static: false })
	public routerElement!: ElementRef;

	public ui: { header?: any } = { header: this._ui.header.get() };
	public user = this._user.getData();
	public region = this._user.getRegion();
	public readonly APP_VERSION: string;

	constructor(
		public readonly _ui: UiService,
		private readonly _debug: DebugService,
		private readonly _dialog: DialogService,
		private readonly _swUpdate: SwUpdate,
		private readonly _translate: TranslateService,
		private readonly _user: UserService,
	) {
		this.APP_VERSION = Object.create(APP_VERSION)?.version;
		this._translate.use('fr-FR');

		if (!environment.production) {
			const body = document.querySelector('body');
			if (body !== null) body.classList.add('environment-development');
		}
	}

	public ngOnInit(): void {
		this.upgradePWACheck();
		this._ui.header.subject.pipe(
			distinctUntilChanged(),
			debounceTime(50),
		).subscribe((header: any) => {
			this.ui.header = header;
		});

		this._user.onUpdate.subscribe((update) => {
			this.user = update.user;
			this.region = update.region;
			this._ui.header.set({ toolbarShow: update.logged });
			if (update.region?.id) this._ui.theme = update.region.id as any;
		});
	}

	public ngAfterViewInit(): void {
		this._ui.content.subject.pipe(
			delay(200),
			filter(({ scroll }) => scroll !== undefined),
			filter(() => this.routerElement.nativeElement.parentElement.querySelector('ng-component')),
		).subscribe(({ scroll }) => {
			this.routerElement
				.nativeElement
				.parentElement
				.querySelector('ng-component')
				.scroll(...scroll!);
		});
	}

	public openSimulator(): void { this._dialog.openSimulator(); }

	public openPartSimulator(): void { this._dialog.openSimulator(true); }

	public sidenavDebug(): void { this._debug.dialogDebug(); }

	public openEquipment(): void {
		const urlParams = stringify({
			prefill_Firstname: this.user?.firstName ?? '',
			prefill_Name: this.user?.lastName ?? '',
		});
		window.open(`https://airtable.com/shrpFus5NpbnALRfD?${urlParams}`, '_blank');
	}

	public openExpenseReportForm(): void {
		const urlParams = stringify({
			prefill_prenom: this.user?.firstName ?? '',
			prefill_nom: this.user?.lastName ?? '',
		});
		window.open(`https://airtable.com/shrvzMzUNj7TFqIHA?${urlParams}`, '_blank');
	}

	private upgradePWACheck(): void {
		if (this._swUpdate.isEnabled) {
			this._swUpdate.versionUpdates.pipe(
				filter(({ type }: VersionEvent) => type === 'VERSION_DETECTED'),
			).subscribe({
				next: () => {
					const dialog = this._dialog.openMessage({
						title: 'Nouvelle version',
						content: `Une nouvelle version de l'app est disponible. Mettre à jour ?`,
						close: true,
						buttons: [
							{
								iconLeft: 'close',
								title: 'Non, plus tard',
								click: () => dialog.close(),
							},
							{
								iconLeft: 'restart_alt',
								title: 'Oui, redémarrer',
								click: () => this.applyUpdate(dialog),
							},
						],
					});
				},
			});
		}
	}

	private applyUpdate(dialog: MatDialogRef<unknown, any>): void {
		window.location.reload();
		dialog.close();
		this._dialog.openMessage({ title: 'Redémarrage...', contentLoader: true });
	}
}
