import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, filter, finalize, map, of } from 'rxjs';

import { Route } from '@ripeurs/common/types/common/route';

import { environment } from 'src/environments/environment';
import { LocalStorage } from '~app/common/decorator/local-storage.decorator';

import { HttpParamsQS } from '../helpers/http';
import { DebugService } from './debug.service';
import { DialogService } from './dialog.service';
import { UiService } from './ui.service';

@Injectable({ providedIn: 'root' })
export class ApiV2Service {
	private readonly config = { url: environment.app.apiV2.url };
	@LocalStorage('user_token') private readonly token: string | null | undefined;

	constructor(
		private readonly _debug: DebugService,
		private readonly _dialog: DialogService,
		private readonly _http: HttpClient,
		private readonly _ui: UiService,
	) {}

	public request<R extends Route.IO = Route.IO>(
		{ method, path }: Route.Config,
		{ params = {}, query = {}, body = {} }: R['request'],
		{ reportProgress, loaderHeader } = { reportProgress: false, loaderHeader: true },
	) {
		const ripID = Math.round(Math.random() * 1000000000)
			.toString(36)
			.toUpperCase()
			.replace(/[01OIL]/gi, '8');
		const headers: { [k: string]: string } = { 'Content-Type': 'application/json', 'X-Ripeurs-Rid': ripID };
		if (this.token) headers['X-Auth-Token'] = this.token;

		let reqPath = path;
		for (const paramName in params) {
			reqPath = reqPath.replace(':' + paramName, encodeURIComponent(params[paramName]));
		}

		if (reqPath[0] === '/') reqPath = reqPath.slice(1);

		const reqQuery = new HttpParamsQS({ fromObject: query });

		let reqBody: string | FormData | null;
		if (body instanceof FormData) {
			reqBody = <FormData>body;
			delete headers['Content-Type'];
		} else if (body !== null) {
			reqBody = JSON.stringify(body);
		} else {
			reqBody = null;
		}

		const req = new HttpRequest(
			method,
			this.config.url + reqPath,
			reqBody,
			{
				params: reqQuery,
				headers: new HttpHeaders(headers),
				responseType: 'json',
				reportProgress: reportProgress,
			},
		);
		const reqClone = req.clone();

		if (loaderHeader) {
			this._ui.header.set({ loaderBarShow: true });
		}

		return this._http.request(req).pipe(
			filter(event => reportProgress || event instanceof HttpResponse),
			map(httpResponse => reportProgress ? httpResponse : (httpResponse as HttpResponse<R>).body),
			catchError((error) => {
				this.requestError(error, reqClone);
				return of(error);
			}), finalize(() => {
				if (loaderHeader) {
					this._ui.header.set({ loaderBarShow: false });
				}
			}),
		);
	}

	private requestError<T>(
		error: any,
		request: HttpRequest<T>,
	) {
		let title = 'Erreur lors de la requete';
		let content = `
			Une erreur s'est produit entre toi et le serveur, essaye de recommencer.
			Si le problème persiste, envoie un rapport de bug automatique en cliquant sur l'icône en haut à droite.
		`;
		if (!navigator.onLine) {
			title = 'Problème de connexion';
			content = `Vérifie ta connexion internet, et recommence.`;
		}
		this._dialog.openMessage({
			title: title,
			content: content,
			debugId: `RID-V2: ${request.headers.get('X-Ripeurs-Rid')}`,
			buttons: [{ title: `Ok` }],
			debug: () => {
				const requestDebug = {
					method: request.method,
					url: request.url,
					urlWithParams: request.urlWithParams,
					headers: (() => {
						const headers: { [k: string]: string | null } = {};
						for (const key of request.headers.keys()) {
							headers[key] = request.headers.get(key);
						}
						return headers;
					})(),
					params: (<HttpParamsQS>request.params).__params,
					body: (() => {
						if (request.body instanceof FormData) return 'FormData';
						return JSON.parse(<string>request.body);
					})(),
				};

				this._debug.mailDebug('HTTP-Global', requestDebug);
			},
		});

		console.log(`----------------------------------------------------------- API-V2 - ERROR`);
		throw error;
	}
}
